/* Overriding existing classes from external themes  */

.MuiToolbar-regular {
  min-height: 0px !important;
}

/* MuiMenu-paper Component-paper-8 MuiPopover-paper MuiPaper-elevation0 MuiPaper-rounded */

.MuiPaper-root {
  margin-top: 5px !important;
  border-radius: 15px !important;
}

.row {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 0px !important;
  margin: 0px !important;
  z-index: 10 !important;
  display: flex !important;
  justify-content: space-between !important;
  flex-direction: row !important;
}

.column {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
