.smallbox-container {
  background: #fafafa;
  min-height: 100vh;
  padding-top: 100px;
}

.smallbox {
  width: 700px;
  max-width: max-content;
  margin: 0 auto;
  border: 1px solid #dbdbdb;
  background: white;
  padding: 20px;
  border-radius: 4px;
  min-height: min-content;
  /* height: 500px; */
  margin-bottom: 20px;
  min-width: 250px;
  display: flex;
  flex-direction: column;
}

strong {
  color: green;
}
