.blob {
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  padding: 15px !important;
  transform: translateZ(0) translate(-50%, -50%) !important;
}

.blob-red {
  background: rgba(255, 82, 82, 0.7);
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: pulse-red 3s infinite;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

.blob-yellow {
  background: rgba(255, 177, 66, 0.8);
  box-shadow: 0 0 0 0 rgba(255, 177, 66, 1);
  animation: pulse-yellow 3s infinite;
}

@keyframes pulse-yellow {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 177, 66, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 177, 66, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 177, 66, 0);
  }
}

@keyframes pulse-blue {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(52, 172, 224, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
  }
}

.blob-green {
  background: rgba(51, 217, 178, 0.8);
  box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: pulse-green 3s infinite;
}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
  }
}

.blob-purple {
  background: rgba(142, 68, 173, 0.8);
  box-shadow: 0 0 0 0 rgba(142, 68, 173, 1);
  animation: pulse-purple 3s infinite;
}

@keyframes pulse-purple {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(142, 68, 173, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(142, 68, 173, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(142, 68, 173, 0);
  }
}

.mapboxgl-ctrl-geocoder .suggestions {
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.08);
  /* box-shadow: none; */
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  margin-top: -12px !important;
}

.mapboxgl-ctrl-geocoder {
  font-size: 18px !important;
  min-width: 340px !important;
  width: 300px !important;
  padding: 6px;
  padding-right: 43px !important;
  padding-left: 0px !important;
  line-height: 25px !important;
  position: absolute !important;
  left: 0px !important;
  user-select: none !important;
  box-shadow: none !important;
  margin-left: 80px !important;
  float: left !important;
  z-index: 105 !important;
  /* background: yellow; */
  transition: 0ms !important;
  background: transparent;
}

.mapboxgl-ctrl-geocoder:hover {
  background: transparent;
  transition: 0ms !important;
}

.suggestions {
  /* border-radius: 20px !important; */
  z-index: 9941 !important;
}

.mapboxgl-ctrl-geocoder--icon-search {
  top: 10px;
  /* left: 354px; */
  /* width: 26px; */
  width: 0px !important;
  height: 0px !important;
  /* height: 26px; */
  opacity: 0.7;
  display: none !important;
}

.mapboxgl-ctrl-geocoder--input {
  width: 360px !important;
  padding-left: 5px !important;
  border-radius: 0px !important;
  padding-right: 40px !important;
  background: transparent;
  height: 49px;
  margin-top: -6px;
  color: rgb(26, 26, 26) !important;
  opacity: 1;
}

.mapboxgl-ctrl-geocoder input {
  margin-left: 8px !important;
}

.mapboxgl-ctrl-geocoder input:hover {
  opacity: 0.8;
  /* background: #fefefe; */
}
.mapboxgl-ctrl-geocoder input:active {
  background: rgba(255, 255, 255, 0.4);
  opacity: 0.6;
  /* background: #fcfcfc; */
}

.mapboxgl-ctrl {
  border-radius: 0px !important;
  z-index: 999 !important;
}

.mapboxgl-ctrl-geocoder--icon {
  /* display: none !important; */
  /* display: block; */
}

.mapboxgl-ctrl-geocoder .mapboxgl-ctrl {
  /* display: none !important; */
  background: green !important;
  width: 0px !important;
}

.mapboxgl-ctrl-geocoder--button {
  width: 32px;
  height: 32px;
  opacity: 0.5;
  transform: translateX(-35px) translateY(4px);
  border-radius: 50px;
  padding-bottom: 3px;
  background: transparent !important;
}
.mapboxgl-ctrl-geocoder--button:hover {
  opacity: 0.4;
}

.mapboxgl-ctrl-geocoder--icon-close {
  /* animation-name: spin;
  animation-duration: 1000ms;
  animation-timing-function: linear; */
  margin-left: 30px;
  margin-top: -6px;
  width: 22px;
  height: 22px;
  opacity: 1;
  width: 50px;
  z-index: 1392343339 !important;
  background: transparent;
}

.mapboxgl-ctrl-geocoder--icon-loading {
  width: 31px;
  height: 31px;
  margin-top: 4px;
  right: 343px !important;
  background: white;
  border-radius: 50%;
}

@media (max-width: 640px) {
  .mapboxgl-ctrl-geocoder {
    width: 100% !important;
    max-width: 100% !important;
    margin: 0px !important;
    border-radius: 0px !important;
  }
}

.popup-address {
  border-radius: 20px !important;
  max-width: 250px !important;
}

.my-pin {
  /* margin-top: -20px !important; */
  padding: 0px !important;
  font-size: 50px;
  /* transform: translateZ(0) translate(-50%, -70%); */
}

.marker-pin {
  border-radius: 50% !important;
  max-width: 0px !important;
}
.spot-pin {
  font-size: 35px;
  /* animation-name: spin; */
  /* animation-duration: 9000ms; */
  /* animation-iteration-count: infinite; */
  /* animation-timing-function: linear; */
  /* transform: translateZ(0) translate(-20%, -58%) !important; */
  /* margin-top: -28px;
  margin-left: -18px; */
  transition: 0.1s;
  /* border: 1px solid white !important; */
  border-radius: 100% !important;
  /* background: white !important; */
  /* max-width: 0px; */
}
.spot-pin:hover {
  filter: brightness(130%);
  /* opacity: 0.8; */
  /* transition: 0.1s; */
}
.spot-pin:active {
  transform: scale(1.15);
  filter: brightness(130%);
  /* opacity: 0.9; */
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.mapboxgl-ctrl-geocoder input::placeholder {
  /* color: transparent !important; */
  color: rgb(94, 94, 94) !important;
  font-size: 18px !important;
}
