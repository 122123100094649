/* 
export NODE_OPTIONS=--openssl-legacy-provider
*/

/* Main CSS */

/* @font-face {
  font-family: "Gilroy-ExtraBold";
  src: local("Gilroy-ExtraBold");
    url(./assets/Gilroy-ExtraBold.otf) format("truetype");
} */

/*
@font-face {
  font-family: "Web Sans Thin";
  src: local("Web Sans Thin"),
    url("./assets/SFNSDisplay-Thin.otf") format("truetype");
}

@font-face {
  font-family: "Web Sans Light";
  src: local("Web Sans Light"),
    url("./assets/SFNSDisplay-Light.otf") format("truetype");
}

@font-face {
  font-family: "Web Sans Regular";
  src: local("Web Sans Regular"),
    url("./assets/SFNSDisplay-Regular.otf") format("truetype");
}

@font-face {
  font-family: "Web Sans Semibold";
  src: local("Web Sans Semibold"),
    url("./assets/SFNSDisplay-Semibold.otf") format("truetype");
} */

* {
  text-decoration: none !important;
  font-family: "Gilroy-Regular", sans-serif !important;
  outline: none !important;
  /* overflow: hidden !important; */
}

strong {
  font-family: "Gilroy-Medium", sans-serif !important;
}

.lighter {
  font-family: "Gilroy-Light", sans-serif !important;
}
.bolder {
  font-family: "Gilroy-Medium", sans-serif !important;
}

body {
  /* position: absolute; */
}

ul {
  padding: 0px !important;
  margin: 0px !important;
  list-style: none !important;
}

.logo-text {
  background: -webkit-linear-gradient(55deg, #434343, #373737);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* font-family: "Torus SemiBold", sans-serif !important; */
  font-family: "Gilroy-SemiBold", sans-serif !important;
}

.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.glass {
  backdrop-filter: blur(4px) !important;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px !important;
}

.glass-hard {
  background: rgba(255, 255, 255, 0.98) !important;
  box-shadow: rgba(60, 64, 67, 0.4) 0px 2px 5px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px !important;
}

.bubble {
  border-radius: 20px !important;
  padding: 15px !important;
  margin: 10px !important;
  margin-top: -0px !important;
  font-size: 15px !important;
  z-index: 99 !important;
}

.fat-bubble {
  border-radius: 25px;
  padding: 3px;
  z-index: 5 !important;
}

.avatar {
  border-radius: 50%;
  width: 45px;
  height: 45px;
  cursor: pointer;
  user-select: none !important;
}
.live:hover {
  filter: brightness(104%);
}
.live:active {
  transform: scale(0.96);
}
.navbar {
  z-index: 100 !important;
  /* padding: 20px; */
  position: fixed;
  width: 100%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.left-bar {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  position: fixed;
  left: 0px;
  margin: 12px;
  border-radius: 7px;
  transform: translateZ(0);
}

.left-bar:hover {
  /* zoom: 103%; */
  color: rgb(50, 50, 50) !important;
}

.search-input::placeholder {
  opacity: 1 !important;
}

.right-bar {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  position: fixed;
  right: 0 !important;
  z-index: 100;
  margin: 20px;
}

.side-bar {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: fixed;
  /* z-index: 1 !important; */
  /* margin: 0px; */
  /* height: max-content !important; */

  /* margin-left: 10px; */
  border-radius: 50px;
  /* background: white; */
  /* margin-top: 100px; */
  /* padding: 50px; */
  /* width: 124px; */
  /* padding: 10px; */
  /* box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 36px 0px !important; */
  background: yellow;
  /* background: yellow; */
  /* backdrop-filter: blur(8px); */
  /* transition: all 0.1s;
    position: absolute;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%); */
  width: 400px;
}

.databar-toggle {
  position: absolute;
  top: 320px;
  background: #fff;
  /* padding: 8px; */
  padding-top: 15px;
  padding-left: 9px;
  padding-right: 5px;
  padding-bottom: 15px;
  user-select: none !important;
  cursor: pointer;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  clip-path: inset(-10px -5px -10px -1px);
  z-index: 90;
}
.databar-toggle:hover {
  padding: 12px;
  padding-top: 15px;
  padding-bottom: 15px;
  background: #f4f4f4;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px;
}

.databar {
  height: calc(100% - 110px);
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  z-index: 99;
  overflow: auto !important;
  max-width: 448px !important;
  position: absolute;
}

.show-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.show-scroll::-webkit-scrollbar {
  width: 6px;
  height: 0px;
  background-color: #f5f5f5;
}

.show-scroll::-webkit-scrollbar-thumb {
  background-color: #727272;
}

/* ---- */

.hide-scroll::-webkit-scrollbar-track {
  width: 0px !important;
}

.hide-scroll::-webkit-scrollbar {
  width: 0px !important;
  height: 0px;
}

.hide-scroll::-webkit-scrollbar-thumb {
  width: 0px !important;
}

.close .marker-span {
  background: red !important;
  padding: 20px !important;
}

.x {
  user-select: none !important;
  color: #dbdbdb;
  cursor: pointer;
  border-radius: 50%;
  padding-left: 5px;
  padding-right: 6px;
  padding-top: 2px;
  font-size: 12px;
  vertical-align: middle !important;
}
.x:hover {
  background: #dbdbdb;
  color: white;
}
.x:active {
  background: silver;
}

.drop-icon {
  font-size: 30px !important;
  padding-right: 10px;
  margin-top: -2px;
}

.dark {
  background: black !important;
}
.light {
  background: red !important;
}

.left-side {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 105;
  height: 100%;
  /* border-right: 2px solid blue; */
}

.toolbar-container {
  z-index: 14444405;
  background: #fff;
  position: absolute;
  width: 100%;
  height: 0px;
  background: red;
  bottom: 60px;
  float: bottom;
  width: 448px;
}
.toolbar {
  /* background: #fafafa; */
  width: 448px;
  justify-content: space-around;
  /* border-top: 1px solid #dbdbdb; */
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 12px; */
  height: 60px;
  background: red !important;
  border-top: 1px solid #dbdbdb;
  background: #fff;
  bottom: 0px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 10px 10px;
}
.toolbar-box {
  text-align: center;
  margin: 0 auto;
  width: 20%;
  padding: 17px;
  cursor: pointer;
  z-index: 999 !important;
  background: #fff;
  border: 1px solid #fafafa;
  border-top: 0px;
}
.toolbar-box:hover {
  background: #f7f7f7;
}

.toolbar-box:active {
  background: #f2f1f1;
}

.toolbar-icon {
  font-size: 25px;
  cursor: pointer;
  margin-top: -4px;
}

.title-address {
  font-size: 30px;
  /* color: #fefefe; */
}

.img-box {
  background-size: cover;
  width: 100%;
  height: 157px;
  background-repeat: no-repeat;
  background-position: center;
}

.div-a3c0al4f {
  /* margin-top: 0px; */
  background: #fff;
  border-bottom: 1px solid #dbdbdb;
}

.hamburger-box {
  width: 58px;
  padding: 10px;
  text-align: center;
  z-index: 101 !important;
  position: absolute;
  /* background: yellow; */
  cursor: pointer;
  /* background: blue; */
  background: #fff;
  max-height: 49px;
}

.hamburger-box:hover {
  /* background: #fafafa; */
}
.hamburger {
  margin-top: 3px;
  width: 22px;
  height: 22px;
  color: rgb(60, 60, 60);
}

.search-icon {
  margin-top: 13px;
  width: 22px;
  height: 22px;
  margin-left: 412px;
  color: rgb(131, 131, 131);
}

.topbar-box {
  /* background: red; */
  width: 448px;
  /* box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, */
  /* rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; */

  /* box-shadow: 5px 0 5px -5px rgba(0,0,0,0.7),
-5px 0 5px -5px rgba(0,0,0,0.7); */
  z-index: 101;
  /* border-bottom: 1px solid #fafafa; */
  border-bottom: 1px solid #dbdbdb;
}

.input-search-box {
  height: 49px;
  background: #fff;
  width: 100%;
}
.input-search-box:hover {
  min-width: 448px !important;
}
.input-search-box:active {
}

.search-placeholder {
  position: absolute;
  left: 49px !important;
  /* background: yellow !important; */
  width: 200px;
  height: 40px;
  margin-top: 8px;
  color: grey;
  font-size: 20px;
  padding: 4px;
  z-index: 101;
  width: 330px;
}

.logo-inside-search {
  color: red;
  font-size: 24px;
  margin-top: 0px;
  margin-left: 6px;
  user-select: none;
  /* cursor: pointer; */
  z-index: 2900 !important;
  position: absolute;
}

.logo-inside-search:hover {
  /* color: rgb(255, 153, 153); */
}
.logo-inside-search:active {
  filter: brightness(97%);
}

.result-box {
  padding: 15px;
  border-bottom: 1px solid #f2f2f2;
  justify-content: flex-start;
  min-height: 82px;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background: #fff;
}

.result-box:hover {
  background: #fafafa;
}

.result-box:active {
  background: #f5f5f5;
}

.result-box-skeleton {
  justify-content: space-around;
  width: 100%;
}

.result-img {
  background-size: cover;
  width: 48px;
  height: 48px;
  margin-top: 1px;
}

.thumbnail-img {
  background-size: cover;
  width: 22px;
  height: 22px;
  margin-top: 0px;
  margin-right: 6px;
}

.result-title {
  font-size: 16px;
}

.result-subtitle {
  font-size: 13px;
}

.address-skeleton {
  margin-top: 10px;
  margin-bottom: 15px;
  /* margin-bottom: 30px; */
}

.address-view-text {
  z-index: 151;
  background: #fefefe;
}

.sticky-top {
  position: sticky !important;
  top: 0px !important;
}
.address-view {
  /* border-bottom: 1px solid #dbdbdb; */
  background: #fefefe;
  max-height: 158px;
  height: 158px;
}

.address-tabs {
  width: 100%;
  justify-content: space-between;
  /* border-bottom: 1px solid #dbdbdb; */
  position: relative;
  top: -1px;
  background: #fefefe;
  /* box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.339); */
}

.address-tab {
  width: 34%;
  text-align: center;
  cursor: pointer;
  background: #fefefe;
  border-bottom: 1px solid #dbdbdb;
  border-left: 1px solid transparent;
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  padding: 10px;
  font-size: 12px;
  color: rgb(97, 97, 97);
  user-select: none;
  letter-spacing: 1px;
  opacity: 0.8;
  /* font-family: Gilroy-ExtraBold !important; */
}

.address-tab:hover {
  opacity: 1;
}
.address-tab:active {
  opacity: 0.8;
}

.address-tab-selected {
  border-bottom: 1px solid white;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 2px solid grey;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  color: black;
  opacity: 1 !important;
}
.address-tab-icon {
  margin-right: 2px;
  font-size: 16px;
  color: grey;
  margin-top: -2px;
}

.red-pin {
  font-size: 65px;
  color: red;
  cursor: grab;
  transition: 0ms;
}

.red-pin:hover {
  cursor: grab;
  opacity: 0.6;
}

.red-pin:active {
  cursor: grabbing;
  font-size: 59px;
  margin-top: 2px;
  margin-left: 3px;
  opacity: 0.6;
}

.home-button {
  cursor: pointer;
  /* border: 2px solid red; */
  transition: 100ms;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  user-select: none;
  outline: none;
  user-drag: none;
  -webkit-user-drag: none;
}

.home-button img {
  user-select: none;
  outline: none;
  user-drag: none;
  -webkit-user-drag: none;
}

.home-button:hover {
  transform: scale(1.05);
  filter: brightness(105%);
}

.home-button:active {
  transform: scale(1);
}

.home-text {
  /* border: 2px solid red; */
  font-size: 11px;
  margin-top: -25px;
  /* margin-left: 5px; */
  align-self: center;
  font-weight: 900 !important;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
  color: grey;
}
